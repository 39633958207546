<template>
  <main style="margin-top: 40px">
    <v-container fluid class="full-height">
      <v-row>
        <v-col>
          <h3>User Workflow Data</h3>
          <v-data-table
            fixed-header
            height="600px"
            :headers="headers"
            :items="usersForCommentHistory"
            :search="search"
            :loading="loading"
            sort-by="modified"
            loading-text="Loading ... Please wait"
            :items-per-page="25"
            :footer-props="{
              'items-per-page-options': [25,50,100,-1]
            }"
          >
            <template v-slot:top>
              <v-text-field
                v-model="search"
                label="Search Petitioner or Respondent"
                class="ma-4 mt-10"
                outlined
                prepend-icon="search"
                clearable
                rounded
              />
            </template>

            <template v-slot:item.completed="{ item }">
              <v-icon
                v-if="isComplete(item)"
                title="Completed"
                color="primary"
                small
              >
                mdi-check
              </v-icon>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y :key="item.id">
                <template v-slot:activator="{ on: on_click, attrs: attrs_menu }">
                  <v-btn
                    icon
                    color="primary"
                    v-bind="attrs_menu"
                    v-on="on_click"
                    :loading="menuLoading(item.id)"
                  >
                    <v-icon
                      color="primary"
                    >
                      mdi-dots-horizontal
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-btn
                      text
                      @click="redirect(`/legal-form-guide/divorce-forms/${item.id}`)"
                    >
                      Petitioner Form
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                      text
                      @click="redirect(`/respondent-guide/${item.id}`)"
                    >
                      Respondent Form
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-dialog max-width="700px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          :loading="formLoading === item.id"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Change Questionnaire
                        </v-btn>
                      </template>

                      <template v-slot:default="dialog">
                        <v-card>
                          <v-card-title>
                            Change Questionnaire
                          </v-card-title>
                          <v-card-text>
                            <v-select 
                              :items="publishedWorkflows"
                              v-model="item.parentId"
                              item-text="name"
                              item-value="id"
                              @change="changeForm(item, dialog, $event)"
                            />
                          </v-card-text>
                        </v-card>
                      </template>
                    </v-dialog>
                  </v-list-item>
                  <v-list-item>
                    <v-dialog scrollable max-width="700px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          v-bind="attrs"
                          v-on="on"
                        >
                          Comment History
                        </v-btn>                    
                      </template>

                      <template v-slot:default="dialog">
                        <v-card>
                          <v-card-title class="d-flex justify-space-between elevation-3" style="background-image: linear-gradient(to right, #409cff, #26BAA3, #26BAA3, #33CC98); color: white;">
                            <div>
                              <span class="text-capitalize">{{ item.owner.name }}</span>
                              <span class="font-weight-400"> & </span>
                              <span class="text-capitalize">{{ item.sharingProps[0]?.user.name }}</span>
                            </div>
                          </v-card-title>
                          <v-divider></v-divider>

                          <v-card-text class="text-left pt-10">
                            <p v-if="!activeCommentHistory.length">
                              Sorry, there is no comment history found
                            </p>

                            <div v-else
                              :class="['comment-bubble', { 'petitioner' : activeComment.commentOwner === 'petitioner' }]"
                              v-for="activeComment in activeCommentHistory"
                              :key="activeComment.commentId"
                            >

                              <div class="d-flex justify-space-between">
                                <strong class="text-capitalize d-flex align-center">
                                  <v-icon color="primary" class="me-1">
                                    person
                                  </v-icon>
                                  {{ activeComment.commentOwnerName }}

                                  <small class="font-weight-400 mx-2 font-italic">
                                    ({{ activeComment.commentOwner }})
                                  </small>
                                </strong>

                                <time class="font-size-small d-flex align-center">
                                  <v-icon small color="primary" class="me-1">
                                    event
                                  </v-icon>
                                  {{ dateFormat(activeComment) }}

                                  <v-icon small color="primary" class="ms-2 me-1">
                                    schedule
                                  </v-icon>
                                  {{ timeFormat(activeComment) }}
                                </time>
                              </div>

                              <div v-for="(comments,j) in activeComment.comments" :key="j">
                                <template v-if="comments.comment.length">
                                  <small class="d-block mt-3 mb-1 page-tab">
                                    <strong> {{ comments.page }} </strong>
                                  </small>
                                  <div v-html="comments.comment" class="description"></div>
                                </template>
                              </div>
                            </div>
                          </v-card-text>
                          <v-divider></v-divider>
                          <v-card-actions>
                            <v-btn
                              color="error"
                              text
                              @click="dialog.value = false"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-dialog>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                      text
                      :loading="resetUserId === item.id"
                      @click="restartUserWorkflow(item)"
                    >
                      Reset User Workflow
                    </v-btn>
                  </v-list-item>
                  <v-list-item v-if="canResendRespondentEmail(item)">
                    <v-btn
                      text
                      :loading="resetUserId === item.id"
                      @click="resendRespondentEmail(item)"
                    >
                      Resend Registration To Respondent
                    </v-btn>
                  </v-list-item>                  
                  <v-list-item v-if="item.finalPDF">
                    <v-btn
                      text
                      :loading="(pdfToDelete === item.id)"
                      @click="deleteFinalPdf(item)"
                    >
                      Delete PDF
                    </v-btn>
                  </v-list-item>
                  <v-list-item v-if="item.finalPDF">
                    <v-btn                      
                      text
                      @click="downloadClick(item.finalPDF)"
                    >
                      Download PDF
                    </v-btn>                    
                  </v-list-item>
                  <v-list-item v-else>
                    <v-btn
                      text
                      :loading="pdfGenerating === item.id"
                      @click="generateDoc(item)"
                    >
                      Generate PDF
                    </v-btn>                    
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </main>
</template>

<script>
import userWorkflowDataMixin from "@/mixins/views/userWorkflowData.mixin"

export default {
  name: 'UserWorkflowData',
  
  mixins: [userWorkflowDataMixin]
}
</script>

<style lang="scss">
.text-d-none {
  display:none !important;
}

.comment-bubble {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 12%);
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 30px;
  margin-right: 50px;

  &.petitioner {
    margin-left: 50px;
    margin-right: 0;
  }

  &:nth-child(even) {
    background: rgba(248, 248, 248, 0.25);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 22%);
  }

  .page-tab {
    padding-top: 5px;
    border-top: 1px solid rgba(63, 208, 201, 0.08);
  }

  .description {
    font-size: 14px;

    p {
      margin-bottom: 0;
    }
  }
}
</style>
